import axios from "axios";

axios.defaults.baseURL = '';
//axios.defaults.baseURL = 'http://10.10.75.194:5000/';
//axios.defaults.baseURL = 'http://10.10.75.4:8080/';
//axios.defaults.baseURL = 'http://yaoapi.biobaseyun.com/';
// const api = axios.create({
//         baseURL: '', // 请求的公共地址部分
//     })
//axios.defaults.baseURL = 'http://10.10.75.141:8001/';
export default axios;
//export default api;