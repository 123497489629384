<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <el-icon>
                        <Setting />
                    </el-icon> 系统管理
                </el-breadcrumb-item>
                <el-breadcrumb-item>印章公司管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="content-title">
                印章公司管理
            </div>
            <div class="handle-box">
                <el-input v-model="query.keywords" placeholder="请输入关键字" class="handle-input mr10"></el-input>

                <el-button type="primary" @click="handleSearch">
                    <el-icon>
                        <Search />
                    </el-icon>搜索
                </el-button>
                <el-button type="primary" @click="handleAdd" class="rightbutton" style='position: absolute;right:60px;'>
                    <el-icon>
                        <Plus />
                    </el-icon>添加
                </el-button>

            </div>
            <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
                <el-table-column fixed prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="company" label="公司名称"></el-table-column>
                <el-table-column prop="createuser" label="创建人"></el-table-column>
                <el-table-column prop="createtime" label="创建时间"></el-table-column>
                <el-table-column label="操作" width="250" align="center">
                    <template #default="scope">
                        <el-link icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-link>
                        <el-link icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.size" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="用户编辑" v-model="editVisible" width="40%">
            <el-form label-width="90px" :inline="true">
                 <el-form-item label="id">
                    <el-input v-model="form.id" disabled></el-input>
                </el-form-item>
                <el-form-item label="公司名称">
                    <el-input v-model="form.company"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 新增弹出框 -->
        <el-dialog title="新增公司" v-model="addVisible" width="40%">
            <el-form ref="formRef" :model="formadd" label-width="90px" :inline="true">
                <el-form-item label="公司名称">
                    <el-input v-model="formadd.company"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {
        onMounted,
        ref,
        reactive
    } from "vue";
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    import axios from "axios";
    export default {
        name: "yinzhangcompany",
        setup() {
            const token = sessionStorage.getItem('token');
            const userid = sessionStorage.getItem('user_id');
            const usertype = sessionStorage.getItem('user_type');
            const query = reactive({
                keywords: "",
                page: 1,
                size: 5,
            });
            const tableData = ref([])
            const pageTotal = ref(0);
            //获取表格数据（对接后台)
            const getuserlist = () => {
                const testdata = {
                    keywords: query.keywords,
                    page: query.page,
                    size: query.size
                }
                axios({
                    method: "post",
                    url: '/api/v1/yinzhangcompany/findcompanylist',
                    headers: {
                        Authorization: token,
                    },
                    data: testdata
                }).then(res => {
                    if (res.data.code == "0") {
                        tableData.value = res.data.data.list;
                        pageTotal.value = res.data.data.total;
                        //console.log(tableData.value)
                    }
                }).catch(err => {
                    console.log(err)
                })
            }

            onMounted(() => {
                getuserlist()

            })

            // 查询操作
            const handleSearch = () => {
                query.page = 1;
                getuserlist();
            };
            // 分页导航
            const handlePageChange = (val) => {
                query.page = val;
                getuserlist();
            };

            // 删除操作
            const handleDelete = (index, row) => {
                // 二次确认删除
                ElMessageBox.confirm("确定要删除吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            //userid: userid,
                            id: row.id,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/yinzhangcompany/deleteCompany',
                            headers: {
                                 authorization: token,
                            },
                            data:testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("删除成功!");
                                getuserlist()
                            }
                        }).catch(err => {
                            console.log(err)
                        })

                        //(表格删除一行不走后台)
                        //tableData.value.splice(index, 1);
                        handleSearch()

                    })
                    .catch(() => {});
            };

            // 表格编辑时弹窗和保存
            const editVisible = ref(false);
            let form = reactive({
                userid: userid,
                company: "",
                id:""
            });
            let idx = -1;
            const handleEdit = (index, row) => {
                idx = index;
                Object.keys(form).forEach((item) => {
                    form[item] = row[item];
                });
                editVisible.value = true;
            };
            const saveEdit = () => {
                editVisible.value = false;
                //ElMessage.success(`修改第 ${idx + 1} 行成功`);
                // Object.keys(form).forEach((item) => {
                //     tableData.value[idx][item] = form[item];
                // });
                const testdata = {
                    createuser: userid,
                    company: form.company,
                    id:form.id
                }
                axios({
                    method: 'post',
                    url: '/api/v1/yinzhangcompany/updateCompany',
                    headers: {
                        Authorization: token,
                    },
                    data: testdata
                }).then(res => {
                    //console.log("编辑")
                    console.log(res)
                    if (res.data.code == '0') {
                        ElMessage.success("修改成功!");
                        getuserlist()
                    }
                    if (res.data.code == '1') {
                        ElMessage.success("系统已存在此公司名，请检查后修改");
                        getuserlist()
                    }
                }).catch(err => {
                    console.log(err)
                })
            };

           
            //表格新增时弹窗和保存
            let addVisible = ref(false);
            const formadd = reactive({
                
                company: '',
                
            });
            const formRef = ref(null);
            const handleAdd = () => {

                addVisible.value = true;
            };
            const saveAdd = () => {
                addVisible.value = false;
                const testdata = {
                    createuser: userid,
                    company: formadd.company,
                }
                formRef.value.validate((valid) => {
                    if (valid) {
                        //发送请求
                        //发送 POST 请求
                        axios({
                            method: 'post',
                            url: '/api/v1/yinzhangcompany/addCompany',
                            headers: {
                                Authorization: token,

                            },
                            data: testdata
                            
                        }).then(res => {
                            console.log(res)
                            if (res.data.code == '0') {
                                ElMessage.success("提交成功！");
                            }
                            if (res.data.code == '1') {
                                ElMessage.success("系统已存在此公司名，请检查后上传！");
                            }
                             getuserlist()
                        }).catch(err => {
                            console.log(err)
                        })

                    } else {
                        return false;
                    }
                });

            };

            return {
                query,
                tableData,
                pageTotal,
                editVisible,
                addVisible,
                form,
                formadd,
                handleSearch,
                handlePageChange,
                handleDelete,
                handleEdit,
                saveEdit,
                handleAdd,
                saveAdd,
                formRef,
              
          

            };
        },
    };
</script>

<style scoped>
    .content-title {
        clear: both;
        font-weight: 700;
        line-height: 20px;
        font-size: 20px;
        color: #1f2f3d;
        text-align: center;
        margin-bottom: 30px;
    }

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 200px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>