<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <el-icon>
                        <Paperclip />
                    </el-icon> 印章管理
                </el-breadcrumb-item>
                <el-breadcrumb-item>印章领用查询</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="content-title">
                印章领用查询
            </div>
            <div class="handle-box">
                <el-input v-model="query.keywords" placeholder="请输入关键词" class="handle-input mr10"></el-input>
                <el-date-picker v-model="query.starttime" type="datetime" placeholder="开始时间"
                    format="YYYY/MM/DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss" />
                至
                <el-date-picker v-model="query.endtime" type="datetime" placeholder="结束时间" format="YYYY/MM/DD hh:mm:ss"
                    value-format="YYYY-MM-DD hh:mm:ss" />
                <el-button type="primary" @click="handleSearch"
                    style="margin-left:20px;margin-bottom:5px;margin-right:20px">
                    <el-icon>
                        <Search />
                    </el-icon>搜索
                </el-button>
                <el-switch v-model="value2" class="mb-2"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="领用"
                    inactive-text="外带" />
                <el-button type="primary" @click="downloadyinzhang" class="rightbutton"
                    style='position: absolute;right:60px;'>
                    <el-icon>
                        <Download />
                    </el-icon>导出
                </el-button>

            </div>
            <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
                id="yinzhang">
                <el-table-column fixed prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="userid" label="用户id"></el-table-column>
                <el-table-column prop="name" label="使用人"></el-table-column>
                <el-table-column prop="usetime" label="日期"></el-table-column>
                <el-table-column prop="business" label="事业部"></el-table-column>
                <el-table-column prop="tel" label="电话"></el-table-column>
                <el-table-column prop="yinzhangstyle" label="印章类型"></el-table-column>
                <el-table-column prop="yinzhangcompany" label="印章公司"></el-table-column>
                <el-table-column v-if="false" prop="fawushenpiimg" label="审批图片">
                    <template #default="scope">
                        <el-link type="primary" :href="scope.row.fawushenpiimg" target="_blank">查看图片</el-link>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="isout" label="是否外带" key="isout"></el-table-column> -->

                <el-table-column v-if="value2" prop="duifangname" label="对方名称" key="duifangname"></el-table-column>
                <el-table-column v-if="value2" prop="documenttype" label="用印文件类型" key="documenttype"></el-table-column>
                <el-table-column v-if="value2" prop="hetongtype" label="合同类型" key="hetongtype"></el-table-column>
                <el-table-column v-if="value2" prop="hetongmoney" label="合同金额" key="hetongmoney"></el-table-column>
                <el-table-column v-if="value2" prop="istemplate" label="是否是公司模板" key="istemplate"></el-table-column>
                <el-table-column v-if="value2" prop="ischeck" label="法务是否已审核" key="ischeck"></el-table-column>
                <el-table-column v-if="value2" prop="isfirst" label="非合同模板是否首次使用" key="isfirst"></el-table-column>
                <el-table-column v-if="false" prop="hetongimg" label="合同图片" key="hetongimg">
                    <template #default="scope">
                        <el-link type="primary" :href="scope.row.hetongimg" target="_blank">查看图片</el-link>
                    </template>
                </el-table-column>
                <el-table-column v-if="!value2" prop="outfor" label="外带用途" key="outfor"></el-table-column>
                <el-table-column v-if="!value2" prop="outwhere" label="外带地点" key="outwhere"></el-table-column>
                <el-table-column v-if="!value2" prop="backbutton" label="归还状态" key="backbutton">
                    <template #default="scope">
                        <span v-if="scope.row.backbutton=='0'&&scope.row.isout=='是'" style="color:red">
                            未归还
                        </span>
                        <span v-if="scope.row.backbutton=='1'&&scope.row.isout=='是'">
                                 <el-link style="color:blue" :underline="false"
                            @click="backconfirm(scope.$index, scope.row)">
                            确认
                        </el-link>
                        &nbsp;&nbsp;
                        <el-link style="color:#d18d3f" :underline="false"
                            @click="notback(scope.$index, scope.row)">
                            退回
                        </el-link>
                              
                         
                        </span>
                        <span v-if="scope.row.backbutton=='2'&&scope.row.isout=='是'" style="color:green">
                            已归还,{{scope.row.backbuttontime}}
                        </span>
                        <span v-if="scope.row.isout=='否'">
                            /
                        </span>

                    </template>
                </el-table-column>

                <el-table-column v-if="!value2" prop="backtime" label="归还时间" key="backtime">
                    <template #default="scope">
                        <span v-if="scope.row.backtime-today>0">
                            {{scope.row.backtime}}
                        </span>
                        <span v-else style="color:red">
                            {{scope.row.backtime}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="zuofei" label="作废标志">
                    <template #default="scope">
                        <span v-if="scope.row.zuofei==1" style="color:red">
                            资料已作废
                        </span>
                        <span v-else>
                            /
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="100" align="center">
                    <template #default="scope">
                        <el-link icon="el-icon-edit" style="color:blue" :underline="false"
                            @click="handleDetail(scope.$index, scope.row)">
                            查看详情
                        </el-link>
                        <el-link icon="el-icon-edit" style="color:#d18d3f" :underline="false"
                            @click="deleteziliao(scope.$index, scope.row)">
                            资料作废
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.size" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>
        <!--查看详情-->
        <el-dialog title="印章详情" v-model="editVisible" width="50%">
            <el-form label-width="190px" :inline="true">
                <el-row>
                    <el-form-item label="ID:">
                        <span :bind="form.id">{{form.id}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="使用人:">
                        <span :bind="form.name">{{form.name}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="日期:">
                        <span :bind="form.usetime">{{form.usetime}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="事业部:">
                        <span :bind="form.business">{{form.business}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="电话:">
                        <span :bind="form.tel">{{form.tel}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="印章公司:">
                        <span :bind="form.yinzhangcompany">{{form.yinzhangcompany}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="类型:">
                        <span :bind="form.yinzhangsytle">{{form.yinzhangsytle}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="领导审批图片:">
                        <el-image v-for="(item,index) in form.fawushenpiimglist" :key="index"
                            style="width: 100px; height: 100px" :src=item :preview-src-list="form.fawushenpiimglist"
                            :initial-index="0" fit="cover">
                        </el-image>

                        <!-- <el-image style="width: 100px; height: 100px" :src="form.fawushenpiimg"
                            :preview-src-list="form.fawushenpiimglist" :initial-index="1" fit="cover" /> -->
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="合同金额:">
                        <span :bind="form.hetongmoney">{{form.hetongmoney}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="是否是公司模板:">
                        <span :bind="form.istemplate">{{form.istemplate}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="法务是否已审核:">
                        <span :bind="form.ischeck">{{form.ischeck}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="非合同模板是否首次使用:">
                        <span bind="form.isfirst">{{form.isfirst}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="合同图片:">
                        <el-image v-for="(item,index) in form.hetongimglist" :key="index"
                            style="width: 100px; height: 100px" :src=item :preview-src-list="form.hetongimglist"
                            :initial-index="0" fit="cover">
                        </el-image>
                    </el-form-item>
                </el-row>

                <el-row>
                    <el-form-item label="外带用途:">
                        <span :bind="form.outfor">{{form.outfor}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="外带地点:">
                        <span :bind="form.outwhere">{{form.outwhere}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="归还时间:">
                        <span bind="form.backtime">{{form.backtime}}</span>
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {
        onMounted,
        ref,
        reactive
    } from "vue";
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    import {
        getmachinelist
    } from "../request/api"
    import {
        getCurrentInstance
    } from "vue";
    import {
        getAuthorization
    } from "../request/api";
    import axios from "axios";
    import {
        unzip
    } from "../request/api";
    import {
        formatDateTime
    } from "../request/api";
    import {
        formatDateTime2
    } from "../request/api";
    import FileSaver from 'file-saver';
    import * as XLSX from 'xlsx';

    export default {
        name: "yinzhang",
        setup() {
            const today = formatDateTime2(new Date());
            //console.log(today)
            const value2 = ref(true)
            const token = sessionStorage.getItem('token');;
            const query = reactive({
                starttime: "",
                endtime: today,
                keywords: "",
                page: 1,
                size: 5,
            });
            const tableData = ref([])


            const pageTotal = ref(0);
            // 获取表格数据
            const getmachine = async () => {
                const testdata = {
                    starttime: query.starttime,
                    endtime: query.endtime,
                    keywords: query.keywords,
                    page: query.page,
                    size: query.size
                };
                axios({
                        method: "get",
                        url: '/api/v1/yinzhang/findyinzhang',
                        headers: {
                            Authorization: token,
                        },
                        params: testdata
                    })
                    .then((res) => {
                        //console.log(res)
                        if (res.data.code == "0") {


                            tableData.value = res.data.data.list;
                            pageTotal.value = res.data.data.total;
                            //console.log(res.data.data.list)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            onMounted(() => {
                getmachine()

            })

            // 查询操作
            const handleSearch = () => {
                query.page = 1;
                getmachine();
            };
            // 分页导航
            const handlePageChange = (val) => {
                query.page = val;
                getmachine();
            };
            //导出印章excel
            const onepage1 = () => {
                var wb = XLSX.utils.table_to_book(document.querySelector('#yinzhang')); //关联dom节点
                /* get binary string as output */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(new Blob([wbout], {
                        type: 'application/octet-stream'
                    }), '印章登记信息表.xlsx') //自定义文件名
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout);
                }
                query.size = 5;
                getmachine();
                return wbout
            }
            async function downloadyinzhang() {
                query.size = 10000000;
                getmachine()
                setTimeout(onepage1, 3000)
            }
            // 查看详情
            const editVisible = ref(false);
            let form = reactive({
                id: "",
                name: "",
                usetime: "",
                yinzhangsytle: '',
                business: '',
                tel: '',
                yinzhangcompany: '',
                hetongmoney: '',
                istemplate: '',
                ischeck: '',
                isfirst: '',
                outfor: '',
                outwhere: '',
                backtime: '',
                fawushenpiimg: '',
                fawushenpiimglist: [],
                hetongimglist: [],
            });
            let idx = -1;
            const handleDetail = (index, row) => {
                idx = index;
                Object.keys(form).forEach((item) => {
                    form[item] = row[item];
                });
                // let fawuarr = [row.fawushenpiimg]
                // form.fawushenpiimglist = fawuarr;
                let fawuarr = [];
                if (row.fawushenpiimg) {
                    fawuarr = row.fawushenpiimg.split(',')
                }
                form.fawushenpiimglist = fawuarr
                let hetongarr = [];
                if (row.hetongimg) {
                    hetongarr = row.hetongimg.split(',')
                }

                form.hetongimglist = hetongarr,
                    //console.log(form.fawushenpiimglist)
                //console.log("form.hetongimglist")
                editVisible.value = true;
            };
            //资料作废
            const deleteziliao = (index, row) => {
                // 二次确认删除
                ElMessageBox.confirm("确认作废吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            id: row.id,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/yinzhang/deleteziliao',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("已作废!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })

                        

                    })
                    .catch(() => {});
            };
            //确认归还
             const backconfirm = (index, row) => {
                // 二次确认删除
                ElMessageBox.confirm("确认已归还吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            id: row.id,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/yinzhang/backconfirm',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("已归还!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })

                    

                    })
                    .catch(() => {});
            };
            //确认退回
             const notback = (index, row) => {
                // 二次确认删除
                ElMessageBox.confirm("确认退回吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            id: row.id,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/yinzhang/notback',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("已退回!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })


                    })
                    .catch(() => {});
            };
            return {
                query,
                tableData,
                pageTotal,
                handleSearch,
                handlePageChange,
                downloadyinzhang,
                value2,
                editVisible,
                handleDetail,
                form,
                today,
                deleteziliao,
                backconfirm,
                notback
            };
        },
    };
</script>

<style scoped>
    .content-title {
        clear: both;
        font-weight: 700;
        line-height: 20px;
        font-size: 20px;
        color: #1f2f3d;
        text-align: center;
        margin-bottom: 30px;
    }

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 200px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>