<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <el-icon>
                        <Tickets />
                    </el-icon> 发票管理
                </el-breadcrumb-item>
                <el-breadcrumb-item>发票列表上传</el-breadcrumb-item>
            </el-breadcrumb>
                 
        </div>
        <div class="container">
            <div class="content-title">
                发票列表上传
            </div>
            <el-row>
                <div class="el-form-item__content">
                    <el-upload class="upload-demo" action='' drag :auto-upload="false" :on-change="uploadChange"
                        :limit="1">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将excel文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </div>
            </el-row>
            <el-row>
                 <span style="color:red">注意：1、重复的发票号将无法上传 </span>

            </el-row>
            <el-row>
                 <span style="color:red">注意：2、excel文件中发票时间的格式为2023-03-06 09:18:09这种，格式错误将无法上传或者查询不出来  </span>

            </el-row>
            <el-row>
                <el-button type="primary" size="small" @click="submitUpload">上 传</el-button>
            </el-row>
        </div>
    </div>
</template>

<script>
    import * as XLSX from 'xlsx';
    import {
        readFile
    } from '../request/readFile.js'
    import {
        onMounted,
        ref,
        reactive
    } from "vue";
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    import {
        getCurrentInstance
    } from "vue";
    import {
        getAuthorization
    } from "../request/api";
    import axios from "axios";
    import {
        unzip
    } from "../request/api";
    import {
        formatDateTime
    } from "../request/api";

    export default {
        name: "fapiaoupload",
        setup() {
            const token = sessionStorage.getItem('token');;

            const tableData = ref([]);
            const uploadChange = async (file) => {
                let dataBinary = await readFile(file.raw)
                let workBook = XLSX.read(dataBinary, {
                    type: 'binary',
                    cellDates: true
                });
                let workSheet = workBook.Sheets[workBook.SheetNames[0]]
                const data = XLSX.utils.sheet_to_json(workSheet)
                dealExcel(data)
                //console.log(tableData.value)
            }
            /**
             * excel文件解析后头部的转换
             */
            const dealExcel = (ws) => {
                let keymap = { // 我们要转换的开头
                    "发票号": "fapiaoid",
                    "开票时间": 'fapiaotime',
                    "价税合计": 'amount',
                    "开票公司": 'company',
                    "客户名称": 'client'
                }
                ws.forEach(sourceObj => {
                    Object.keys(sourceObj).map(keys => {
                        let newKey = keymap[keys]
                        if (newKey) {
                            sourceObj[newKey] = sourceObj[keys]
                            delete sourceObj[keys]
                        }
                    })
                })
                tableData.value = ws
            }
            /**
             * 数据批量导入后台
             */
            const submitUpload = () => {
                        axios({
                            method: 'post',
                            url: '/api/v1/fapiao/submitupload',
                             headers: {
                            Authorization: token,
                        },
                            data: tableData.value
                        }).then(res => {
                            //console.log(res)
                            if(res.data.code == '0') {
                                ElMessage.success("添加成功");
                            }
                            if(res.data.code == '2') {
                                ElMessage.success("发现系统存在重复的发票号，请不要重复上传！");
                            }
                            if(res.data.code == '3') {
                                ElMessage.success("时间格式错误！");
                            }
                            if(res.data.code == '4') {
                                ElMessage.success("发现系统存在重复的发票号，请不要重复上传！且时间格式错误！");
                            }
                        }).catch(err => {
                            console.log(err)
                        })
            };
            return {
                uploadChange,
                dealExcel,
                submitUpload,
                tableData
            
            }

        }
    }
</script>

<style scoped>
    .content-title {
        clear: both;
        font-weight: 700;
        line-height: 20px;
        font-size: 20px;
        color: #1f2f3d;
        text-align: center;
    }

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }



    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>