import axios from "./request";
import api from "./request";
import md5 from 'js-md5';
const pako = require('../request/pako.min') //gzip压缩，解压缩
const jwt = require('../request/weapp-jwt') //base64数据格式工具

//档案查询数据接口
export const getdanganData = () => axios.get('/mock/danganlist.json')
    //体检信息查询接口
export const gethealthData = () => axios.get('/mock/healthlist.json')
    //查询体检报告接口
export const gethealthRecord = () => axios.get('/mock/healthrecord.json')
    //身高体重分析接口
export const gethighweight = () => axios.get('/mock/highweight.json')
    //血氧饱和度分析接口
export const getxueyang = () => axios.get('/mock/xueyang.json')
    //血压分析接口
export const getxueya = () => axios.get('/mock/xueya.json')
    //血脂分析接口
export const getxuezhifour = () => axios.get('/mock/xuezhifour.json')
    //血糖尿酸胆固醇三合一接口
export const getxuetangthree = () => axios.get('/mock/xuetangthree.json')
    //血红蛋白接口
export const getxuehongdanbai = () => axios.get('/mock/xuehongdanbai.json')
    //骨密度分析接口
export const getgumidudata = () => axios.get('/mock/gumidu.json')
    //酒精浓度分析接口
export const getjiujingdata = () => axios.get('/mock/jiujing.json')
    //精神压力分析
export const getjingshenyalidata = () => axios.get('/mock/jingshenyali.json')
    //设备管理数据列表接口
export const getmachinelist = () => axios.get('/mock/machinelist.json')
    //设备检测数据接口
export const getmachinetestdata = () => axios.get('/mock/machinetest.json')

//测试接口
export const gettest = (params, testheader) => api({
    method: 'post', // 请求方式
    headers: {
        'Authorization': testheader
    },
    // data: params, // (一般post请求，我们习惯使用 data属性来传参)
    data: params
})

//md5转换
export const getAuthorization = (TimeStamp, Command, Data) => {
        var source = 'admin';
        var publicKey = '5AEE48BBA9967E423B2B7FB91CA7E8FE';
        var privateKey = '5F8B367AF6C92C715F62BD929D8C747C';
        var timeStamp = TimeStamp;
        var command = Command;
        var data = Data;
        var dataStr = JSON.stringify(data).replace(" ", "").replace("/", "").replace("\\", "").replace(" ", "").replace(" ", "");
        console.log('datastr:' + dataStr);
        console.log('test:' + source + publicKey + privateKey + timeStamp + command + dataStr)
        var authorization = md5(source + publicKey + privateKey + timeStamp + command + dataStr);
        //console.log('authorization:' + authorization.toUpperCase());
        return authorization.toUpperCase();

    }
    //md5转换密码
export const getmd5password = (password) => {
    console.log(password)
    var stringpassword = String(password)
    console.log('密码字符:' + stringpassword);
    var md5password = md5(stringpassword)
    return md5password.toUpperCase();
}

//解压缩
export const unzip = (b64Data) => {
    let strData = jwt.weAtob(b64Data),
        charData = strData.split('').map(function(x) { return x.charCodeAt(0); }),
        binData = new Uint8Array(charData),
        data = pako.inflate(binData, { to: 'string' });
    // strData = handleCodePoints(new Uint16Array(data));
    return decodeURIComponent(data)
}

//时间格式转换
export const formatDateTime = (date) => {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    var second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;
    //return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    return y + '-' + m + '-' + d;

};
//时间格式转换2
export const formatDateTime2 = (date) => {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    var second = date.getSeconds();
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;

};