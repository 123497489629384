<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">BIOBASE发票印章管理系统</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="userName">
                    <el-input v-model="param.userName" placeholder="用户名">
                        <template #prepend>
                            <span>
                                <el-icon>
                                    <User />
                                </el-icon>
                            </span>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" placeholder="密码" v-model="param.password" @keyup.enter="submitForm()">
                        <template #prepend>
                            <!-- <el-button icon="el-icon-lock"></el-button> -->
                            <span>
                                <el-icon>
                                    <Lock />
                                </el-icon>
                            </span>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
                <p class="login-tips">Tips : 请输入用户名和密码</p>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        ref,
        reactive
    } from "vue";
    // import { useTagsStore } from '../store/tags'
    import {
        useRouter
    } from "vue-router";
    import {
        ElMessage
    } from "element-plus";
    import {
        getCurrentInstance
    } from 'vue';
    import axios from "axios";
    export default {
        setup() {
            const router = useRouter();
            const param = reactive({
                userName: "",
                password: "",
            });

            const rules = {
                userName: [{
                    required: true,
                    message: "请输入用户名",
                    trigger: "blur",
                }, ],
                password: [{
                    required: true,
                    message: "请输入密码",
                    trigger: "blur"
                }, ],
            };
            const login = ref(null);
            const submitForm = () => {
                login.value.validate((valid) => {
                    if (valid) {
                        axios({
                            method: 'post',
                            url: '/api/v1/user/login',
                            data: {
                                userName: param.userName,
                                password: param.password,
                            },
                        }).then(res => {
                            //console.log(res)
                            //console.log('hh')
                            if (res.data.code == '0') {
                                ElMessage.success("登录成功");
                                //console.log(res.data);
                                sessionStorage.setItem("token", res.data.token);
                                sessionStorage.setItem("user_id",res.data.id);
                                sessionStorage.setItem("user_type",res.data.usertype);
                                sessionStorage.setItem("user_relname",res.data.relname);
                                router.push("/")
                            }else if(res.data.code == '1'){
                                ElMessage.error(res.data.message);
                            }
                        }).catch(err => {
                            console.log(err)
                        })


                    
               } else {
                        ElMessage.error("用户名或密码错误");
                        return false;
                    }
                });
            };

            //const tags = useTagsStore();
            // tags.clearTags();

            return {
                param,
                rules,
                login,
                submitForm,
            };
        },
    };
</script>

<style scoped>
    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../assets/img/caiwubg.jpg);
        background-size: 100%;
    }

    .ms-title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #000;
        border-bottom: 1px solid #ddd;
    }

    .ms-login {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 350px;
        margin: -190px 0 0 -175px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
    }

    .ms-content {
        padding: 30px 30px;
    }

    .login-btn {
        text-align: center;
    }

    .login-btn button {
        width: 100%;
        height: 36px;
        margin-bottom: 10px;
    }

    .login-tips {
        font-size: 12px;
        line-height: 30px;
        color: #fff;
    }
</style>