<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <el-icon>
                        <Setting />
                    </el-icon> 系统设置
                </el-breadcrumb-item>
                <el-breadcrumb-item>领用人员管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="content-title">
                领用人员列表上传
            </div>
            <el-row>
                <div class="el-form-item__content">
                    <el-upload class="upload-demo" action='' drag :auto-upload="false" :on-change="uploadChange"
                        :limit="1">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将excel文件拖到此处，或<em>点击选择文件</em></div>
                    </el-upload>
                </div>

            </el-row>
            <el-row>
                <div class="handle-box">
                    <el-button type="primary" @click="userlistUpload">上 传</el-button>
                    <el-input v-model="query.keywords" placeholder="请输入关键字" class="handle-input mr10"
                        style="width:190px;position: absolute;right:660px;"></el-input>
                    <el-button type="primary" @click="handleSearch" style="position: absolute;right:560px;">
                        <el-icon>
                            <Search />
                        </el-icon>搜索
                    </el-button>
                    <el-button type="primary" @click="alldelete" class="rightbutton"
                        style="position: absolute;right:390px;">
                        <el-icon>
                            <CloseBold />
                        </el-icon>批量删除
                    </el-button>
                    <el-button type="primary" @click="allapply" class="rightbutton"
                        style="position: absolute;right:260px;">
                        <el-icon>
                            <Select />
                        </el-icon>批量同意
                    </el-button>
                    <el-button type="primary" @click="handleAdd" class="rightbutton"
                        style="position: absolute;right:160px;">
                        <el-icon>
                            <Plus />
                        </el-icon>添加
                    </el-button>
                    <el-button type="primary" @click="downloadgetuser" class="rightbutton"
                        style='position: absolute;right:60px;'>
                        <el-icon>
                            <Download />
                        </el-icon>导出
                    </el-button>

                </div>
            </el-row>
            <el-row>
                <el-table :data="tableData1" border class="table" ref="multipleTable"
                    header-cell-class-name="table-header" id="getuserid" @selection-change="handleSelectionChange" :row-key="(row)=>{ return row.id}">
                    <el-table-column type="selection" :reserve-selection="true" width="55" align="center" />
                    <el-table-column fixed prop="id" label="ID" width="55" align="center"></el-table-column>
                    <el-table-column prop="userid" label="员工号"></el-table-column>
                    <el-table-column prop="username" label="员工姓名"></el-table-column>
                    <el-table-column prop="business" label="所属事业部"></el-table-column>
                    <el-table-column prop="tel" label="电话"></el-table-column>
                    <el-table-column prop="apply" label="用户注册审批">
                        <template #default="scope">
                            <span v-if="scope.row.apply==0">
                                <el-link @click="handleApply(scope.$index, scope.row)" style="color:blue">同意
                                </el-link>
                                &nbsp;&nbsp;
                                <el-link @click="deleteApply(scope.$index, scope.row)" style="color:red">不同意
                                </el-link>
                            </span>

                            <span v-else style="color:green">
                                已同意
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="250" align="center">
                        <template #default="scope">
                            <el-link @click="handleEdit(scope.$index, scope.row)">编辑
                            </el-link>
                            &nbsp;&nbsp;
                            <el-link class="red" @click="handleDelete(scope.$index, scope.row)">删除
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                        :page-size="query.size" :total="pageTotal" @current-change="handlePageChange">
                    </el-pagination>
                </div>
            </el-row>
            <!-- 新增弹出框 -->
            <el-dialog title="新增领取人信息" v-model="addVisible" width="40%">
                <el-form ref="formRef" :model="formadd" label-width="90px" :inline="true">
                    <el-form-item label="员工编号">
                        <el-input v-model="formadd.userid"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="formadd.username"></el-input>
                    </el-form-item>
                    <el-form-item label="公司">
                        <el-input v-model="formadd.company"></el-input>
                    </el-form-item>
                    <el-form-item label="事业部">
                        <el-input v-model="formadd.business"></el-input>
                    </el-form-item>
                    <el-form-item label="电话">
                        <el-input v-model="formadd.tel"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="addVisible = false">取 消</el-button>
                        <el-button type="primary" @click="saveAdd">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <!-- 编辑领用人信息弹出框 -->
        <el-dialog title="领用人信息编辑" v-model="editVisible" width="40%">
            <el-form label-width="90px" :inline="true">
                <el-form-item label="员工编号">
                    <el-input v-model="form.userid" disabled></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="公司">
                    <el-input v-model="form.company"></el-input>
                </el-form-item>
                <el-form-item label="事业部">
                    <el-input v-model="form.business"></el-input>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="form.tel"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
    import * as XLSX from 'xlsx';
    import FileSaver from 'file-saver';
    import {
        readFile
    } from '../request/readFile.js'
    import {
        onMounted,
        ref,
        reactive
    } from "vue";
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    import {
        getCurrentInstance
    } from "vue";
    import {
        getAuthorization
    } from "../request/api";
    import axios from "axios";
    import {
        unzip
    } from "../request/api";
    import {
        formatDateTime
    } from "../request/api";

    export default {
        name: "getuserlist",
        setup() {
            let selectedIds = ref([]);
            //  这里的selection是你所有选择的项
            const handleSelectionChange = (selection) => {
                selectedIds = selection.map((item) => item.id);
                console.log(selectedIds);
            }
            const token = sessionStorage.getItem('token');;
            const tableData = ref([])
            const uploadChange = async (file) => {
                let dataBinary = await readFile(file.raw)
                let workBook = XLSX.read(dataBinary, {
                    type: 'binary',
                    cellDates: true
                })
                let workSheet = workBook.Sheets[workBook.SheetNames[0]]
                const data = XLSX.utils.sheet_to_json(workSheet);
                //console.log(data instanceof Array);
                dealExcel(data);


            }
            const dealExcel = (ws) => {
                let keymap = { // 我们要转换的开头
                    "员工编号": "userid",
                    "员工姓名": 'username',
                    "公司": 'company',
                    "事业部": 'business',
                    "电话": 'tel'
                }
                ws.forEach(sourceObj => {
                    Object.keys(sourceObj).map(keys => {
                        let newKey = keymap[keys]
                        if (newKey) {
                            sourceObj[newKey] = sourceObj[keys]
                            delete sourceObj[keys]
                        }
                    })
                })
                tableData.value = ws
                //console.log(tableData.value)
            }
            /**
             * 领用人列表数据批量导入后台
             */
            const userlistUpload = () => {
                axios({
                    method: 'post',
                    url: '/api/v1/appuser/userlistUpload',
                    headers: {
                        Authorization: token,
                    },
                    data: tableData.value
                }).then(res => {
                    //console.log(res)
                    if (res.data.code == '0') {
                        ElMessage.success("上传成功");
                        getmachine()
                    }
                }).catch(err => {
                    console.log(err)
                })
            };
            /**
             * 领用人员列表查询
             */
            const query = reactive({
                keywords: "",
                page: 1,
                size: 5,
            });
            const tableData1 = ref([])

            const pageTotal = ref(0);
            // 获取表格数据
            const getmachine = async () => {
                const testdata = {
                    keywords: query.keywords,
                    page: query.page,
                    size: query.size
                };
                axios({
                        method: "post",
                        url: '/api/v1/user/findgetuserlist',
                        headers: {
                            Authorization: token,
                        },
                        data: testdata
                    })
                    .then((res) => {
                        if (res.data.code == "0") {
                            tableData1.value = res.data.data.list;
                            pageTotal.value = res.data.data.total;
                            //console.log(tableData1.value)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            onMounted(() => {
                getmachine()

            })
            // // 查询操作
            const handleSearch = () => {
                query.page = 1;
                getmachine();
            };
            // // 分页导航
            const handlePageChange = (val) => {
                query.page = val;
                getmachine();
            };
            // 表格编辑时弹窗和保存
            const editVisible = ref(false);
            let form = reactive({
                userid: "",
                username: "",
                company: "",
                business: "",
                tel: ""
            });
            let idx = -1;
            const handleEdit = (index, row) => {
                idx = index;
                Object.keys(form).forEach((item) => {
                    form[item] = row[item];
                });
                editVisible.value = true;
            };
            const saveEdit = () => {
                editVisible.value = false;
                const testdata = {
                    userid: form.userid,
                    username: form.username,
                    company: form.company,
                    business: form.business,
                    tel: form.tel,

                }
                axios({
                    method: 'post',
                    url: '/api/v1/appuser/updateappuser',
                    headers: {
                        Authorization: token,
                    },
                    data: testdata
                }).then(res => {
                    //console.log(res)
                    if (res.data.code == '0') {
                        ElMessage.success("修改成功!");
                        getmachine()
                    }
                }).catch(err => {
                    console.log(err)
                })
            };
            //表格新增时弹窗和保存
            let addVisible = ref(false);
            const formadd = reactive({
                userid: '',
                username: '',
                company: '',
                business: '',
                tel: '',
            });
            const formRef = ref(null);
            const handleAdd = () => {
                addVisible.value = true;
            };
            const saveAdd = () => {
                addVisible.value = false;
                const testdata = {
                    userid: formadd.userid,
                    username: formadd.username,
                    company: formadd.company,
                    business: formadd.business,
                    tel: formadd.tel
                }
                //发送请求
                //发送 POST 请求
                axios({
                    method: 'post',
                    url: '/api/v1/appuser/appuseradd',
                    headers: {
                        Authorization: token,

                    },
                    data: testdata

                }).then(res => {
                    //console.log(res)
                    if (res.data.code == '0') {
                        ElMessage.success("提交成功！");
                        getmachine()
                    }
                }).catch(err => {
                    console.log(err)
                })
            };
            //审批不同意
            const deleteApply = (index, row) => {
                const testdata = {
                    userid: row.userid,
                }
                axios({
                    method: 'post',
                    url: '/api/v1/appuser/deleteapply',
                    headers: {
                        authorization: token,
                    },
                    data: testdata
                }).then(res => {
                    //console.log(res)
                    //console.log("返回结果=" + JSON.stringify(res.data));
                    if (res.data.code == '0') {
                        ElMessage.success("已驳回,请重新发起申请!");
                        getmachine()
                    }
                }).catch(err => {
                    console.log(err)
                })

                handleSearch()


            };
            //批量同意
            const allapply = () => {
                // 二次确认删除
                ElMessageBox.confirm("确定要全部同意吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            idlist: selectedIds,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/appuser/allapply',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("批量同意成功!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                        handleSearch()
                    })
                    .catch(() => {});
            };
            //审批同意操作
            const handleApply = (index, row) => {
                // 二次确认删除
                ElMessageBox.confirm("同意通过注册吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            userid: row.userid,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/appuser/applyappuser',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("已同意!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })

                        handleSearch()

                    })
                    .catch(() => {});
            };
            //批量删除
            const alldelete = () => {
                // 二次确认删除
                ElMessageBox.confirm("确定要全部删除吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            idlist: selectedIds,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/appuser/alldelete',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("批量删除成功!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                        handleSearch()
                    })
                    .catch(() => {});
            };
            // 删除操作
            const handleDelete = (index, row) => {
                // 二次确认删除
                ElMessageBox.confirm("确定要删除吗？", "提示", {
                        type: "warning",
                    })
                    .then(() => {
                        const testdata = {
                            userid: row.userid,
                        }
                        axios({
                            method: 'post',
                            url: '/api/v1/appuser/deleteappuser',
                            headers: {
                                authorization: token,
                            },
                            data: testdata
                        }).then(res => {
                            //console.log(res)
                            //console.log("返回结果=" + JSON.stringify(res.data));
                            if (res.data.code == '0') {
                                ElMessage.success("删除成功!");
                                getmachine()
                            }
                        }).catch(err => {
                            console.log(err)
                        })

                        handleSearch()

                    })
                    .catch(() => {});
            };

            //发票导出
            const onepage = () => {
                var wb = XLSX.utils.table_to_book(document.querySelector('#getuserid')); //关联dom节点
                /* get binary string as output */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(new Blob([wbout], {
                        type: 'application/octet-stream'
                    }), '领用员工表.xlsx') //自定义文件名
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout);
                }
                query.size = 5;
                getmachine();
                return wbout

            }
            async function downloadgetuser() {
                query.size = 10000000;
                getmachine()
                setTimeout(onepage, 500)
            }
            return {
                uploadChange,
                dealExcel,
                tableData1,
                query,
                tableData,
                pageTotal,
                handleSearch,
                handlePageChange,
                downloadgetuser,
                userlistUpload,
                handleAdd,
                saveAdd,
                addVisible,
                formadd,
                editVisible,
                handleEdit,
                saveEdit,
                form,
                handleDelete,
                handleApply,
                deleteApply,
                selectedIds,
                handleSelectionChange,
                alldelete,
                allapply


            }
        }
    }
</script>

<style scoped>
    .content-title {
        clear: both;
        font-weight: 700;
        line-height: 20px;
        font-size: 20px;
        color: #1f2f3d;
        text-align: center;
    }

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }



    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>