<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <el-icon>
                        <Tickets />
                    </el-icon> 发票管理
                </el-breadcrumb-item>
                <el-breadcrumb-item>发票信息查询</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="content-title">
                发票信息查询
            </div>
            <div class="handle-box">
                <el-input v-model="query.keywords" placeholder="请输入关键字" class="handle-input mr10"></el-input>
                <el-date-picker v-model="query.starttime" type="datetime" placeholder="开始时间" format="YYYY/MM/DD hh:mm:ss"
                    value-format="YYYY-MM-DD hh:mm:ss" />
                至
                <el-date-picker v-model="query.endtime" type="datetime" placeholder="结束时间" format="YYYY/MM/DD hh:mm:ss"
                    value-format="YYYY-MM-DD hh:mm:ss" />
                <el-button type="primary" @click="handleSearch" style="margin-left:20px;margin-bottom:5px">
                    <el-icon>
                        <Search />
                    </el-icon>搜索
                </el-button>
                <el-button type="primary" @click="downloadfapiao" class="rightbutton"
                    style='position: absolute;right:60px;'>
                    <el-icon>
                        <Download />
                    </el-icon>导出
                </el-button>

            </div>
            <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
                id="fapiao">
                <el-table-column fixed prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="fapiaoid" label="发票号"></el-table-column>
                <el-table-column prop="fapiaotime" label="开票时间"></el-table-column>
                <el-table-column prop="amount" label="金额(价税合计)"></el-table-column>
                <el-table-column prop="company" label="开票公司"></el-table-column>
                <el-table-column prop="client" label="客户名称"></el-table-column>
                <el-table-column prop="getperson" label="领取人"></el-table-column>
                <el-table-column prop="business" label="所属事业部"></el-table-column>
                <el-table-column prop="gettime" label="领取时间"></el-table-column>
                <el-table-column prop="confirm" label="领取确认">
                    <template #default="scope">
                        <span v-if="scope.row.confirm==0" style="color:red">未领取</span>
                        <span v-if="scope.row.confirm==1" style="color:green">已领取</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <!-- <el-table-column fixed="right" prop="ReportUrl" label="发票详情" width="110" align="center">
                    <template #default="scope">
                        <el-link type="primary" :href="scope.row.ReportUrl" target="_blank">下载/查看发票</el-link>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作" width="100" align="center">
                    <template #default="scope">
                        <el-link icon="el-icon-edit" style="color:blue" :underline="false" @click="handleDetail(scope.$index, scope.row)">
                        查看详情
                        </el-link>
                         <el-link icon="el-icon-edit" style="color:#d18d3f" :underline="false" @click="returnback(scope.$index, scope.row)">
                        领取退回
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.size" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>
        <!--查看详情-->
        <el-dialog title="发票详情" v-model="editVisible" width="50%">
            <el-form label-width="150px" :inline="true">
                <el-row>
                    <el-form-item label="ID:">
                        <span :bind="form.id">{{form.id}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="发票号:">
                        <span :bind="form.fapiaoid">{{form.fapiaoid}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="开票时间:">
                        <span :bind="form.fapiaotime">{{form.fapiaotime}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="金额（价税合计）:">
                        <span :bind="form.amount">{{form.amount}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="开票公司:">
                        <span :bind="form.company">{{form.company}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="客户名称:">
                        <span :bind="form.client">{{form.client}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="领取人:">
                        <span :bind="form.getperson">{{form.getperson}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="所属事业部:">
                        <span :bind="form.business">{{form.business}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="领取时间:">
                        <span :bind="form.gettime">{{form.gettime}}</span>
                    </el-form-item>
                </el-row>
                <!-- <el-row>
                    <el-form-item label="领取确认:">
                        <span :bind="form.confirm">{{form.confirm}}</span>
                    </el-form-item>
                </el-row> -->
                <el-row>
                    <el-form-item label="备注:">
                        <span bind="form.remark">{{form.remark}}</span>
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {
        onMounted,
        ref,
        reactive
    } from "vue";
    import {
        ElMessage,
        ElMessageBox
    } from "element-plus";
    import {
        getCurrentInstance
    } from "vue";
    import axios from "axios";
    import {
        formatDateTime
    } from "../request/api";
     import {
        formatDateTime2
    } from "../request/api";
    import FileSaver from 'file-saver';
    import * as XLSX from 'xlsx';
    import {
        readFile
    } from '../request/readFile.js'
    export default {
        name: "fapiao",
        setup() {
            const token = sessionStorage.getItem('token');
             const today = formatDateTime2(new Date());
            const query = reactive({
                starttime: "",
                endtime: today,
                keywords: "",
                page: 1,
                size: 5,
            });
            const tableData = ref([])
            //console.log(query.endtime)

            const pageTotal = ref(0);
            // 获取表格数据
            const getmachine = async () => {
                const testdata = {
                    starttime: query.starttime,
                    endtime: query.endtime,
                    keywords: query.keywords,
                    page: query.page,
                    size: query.size
                };
                axios({
                        method: "get",
                        url: '/api/v1/fapiao/findfapiao',
                        headers: {
                            Authorization: token,
                        },
                        params: testdata
                    })
                    .then((res) => {
                        if (res.data.code == "0") {
                            tableData.value = res.data.data.list;
                            pageTotal.value = res.data.data.total;
                            //console.log(tableData.value)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            onMounted(() => {
                getmachine()

            })
            // // 查询操作
            const handleSearch = () => {
                query.page = 1;
                getmachine();
            };
            // // 分页导航
            const handlePageChange = (val) => {
                query.page = val;
                getmachine();
            };
            //发票导出
            const onepage = () => {
                var wb = XLSX.utils.table_to_book(document.querySelector('#fapiao')); //关联dom节点
                /* get binary string as output */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(new Blob([wbout], {
                        type: 'application/octet-stream'
                    }), '发票领用信息表.xlsx') //自定义文件名
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout);
                }
                query.size = 5;
                getmachine();
                return wbout

            }
            async function downloadfapiao() {
                query.size = 10000000;
                getmachine()
                setTimeout(onepage, 500)
            }
            // 查看详情
            const editVisible = ref(false);
            let form = reactive({
                id: "",
                fapiaoid: "",
                fapiaotime: "",
                amount: '',
                company: '',
                client: '',
                getperson: '',
                business: '',
                gettime: '',
                confirm: '',
                remark: ''
            });
            let idx = -1;
            const handleDetail = (index, row) => {
                idx = index;
                Object.keys(form).forEach((item) => {
                    form[item] = row[item];
                });
                editVisible.value = true;
            };
            //发票领取退回
            const returnback=(index,row)=>{
                let fapiaoid={fapiaoid:row.fapiaoid}
                console.log(row.fapiaoid)
                    axios({
                    method: 'post',
                    url: '/api/v1/fapiao/returnbackfaiao',
                    headers: {
                        Authorization: token,

                    },
                    data: fapiaoid
                }).then(res => {
                    if (res.data.code == '0') {
                        getmachine();
                        ElMessage.success("退回成功!");
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            return {
                query,
                tableData,
                pageTotal,
                handleSearch,
                handlePageChange,
                downloadfapiao,
                editVisible,
                handleDetail,
                form,
                returnback
            };
        },
    };
</script>

<style scoped>
    .content-title {
        clear: both;
        font-weight: 700;
        line-height: 20px;
        font-size: 20px;
        color: #1f2f3d;
        text-align: center;
        margin-bottom: 30px;
    }

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 200px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>